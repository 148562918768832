import React from 'react';

import { Dot, sizeSwitch } from './Dot';
import { ComponentProps } from '@Components';
import { useTranslation } from '@Providers/TranslationProvider';

export interface GalleryDotsProps extends ComponentProps {
  total: number;
  activeElementIndex: number;
}

const dotWidth = 8;
const gapWidth = 8;
const dotContainerWidth = dotWidth + gapWidth;

/**
 * Gallery dots component with sliding animation
 */
export const GalleryDots: React.FC<React.PropsWithChildren<GalleryDotsProps>> = ({
  className,
  activeElementIndex,
  total,
  'data-id': dataId,
}) => {
  const { t } = useTranslation();
  const visibleElementsCount = Math.min(5, total);

  const leftVisibleBound = total > 5 ? Math.min(Math.max(activeElementIndex - 2, 0), total - 5) : 0;
  const rightVisibleBound = Math.min(leftVisibleBound + 5, total);
  const left = total > 5 ? (rightVisibleBound - 5) * dotContainerWidth : 0;

  return (
    <div
      aria-label={t('imageXOutOfX', { current: activeElementIndex + 1, total })}
      data-id={dataId}
      className={className}
      sx={{
        width: visibleElementsCount * dotContainerWidth,
        overflow: 'hidden',
        display: 'flex',
      }}
    >
      <div
        sx={{
          display: 'grid',
          gridAutoFlow: 'column',
          gridAutoColumns: dotWidth,
          gap: gapWidth,
          padding: `0 ${gapWidth / 2}px`,
          alignItems: 'center',
          justifyItems: 'center',
          transition: 'opacity 100ms ease-out 100ms, left 200ms ease-in-out',
          position: 'relative',
          left: -1 * left,
        }}
      >
        {[ ...Array(total).keys() ].map((index) => {
          const isVisible = index >= leftVisibleBound && index < rightVisibleBound;

          const opacity = activeElementIndex === index ? 1.0 : 0.4;

          return (
            <Dot
              key={index}
              name={`gallery-dot-${index}`}
              opacity={isVisible ? opacity : 0}
              size={sizeSwitch(activeElementIndex, index)}
            />
          );
        })}
      </div>
    </div>
  );
};
